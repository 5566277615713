@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/**************************************
General Styles
***************************************/
body {
  font-family: "Sans Serif";
  background-color: #f4f4f4;
}

.common-margin {
  padding-top: 5rem !important;
  padding-bottom: 4rem !important;
}

/**************************************
Inputs and Textareas
***************************************/
input,
textarea {
  -webkit-appearance: none; /* Remove default styling on iOS */
  appearance: none;
}

input[type="date"],
input[type="time"],
input[type="text"].placeholder-custom {
  background-color: white;
  border: 1px solid #000;
  height: 45px !important;
  color: #000;
}

input[type="text"].placeholder-custom::placeholder {
  color: #999;
}

/**************************************
Buttons
***************************************/
.signupBtn {
  background-color: #1f2836 !important;
  border-radius: 5px !important;
}

.assBtn {
  width: 7rem !important;
  text-align: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
/**************************************
hero
***************************************/
.custom-container {
  background-color: rgba(100, 99, 99, 0.2);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.theme-btn {
  display: inline-block;

  position: relative;

  text-decoration: none;

  z-index: 1;

  padding: 0;

  border: 0;

  transition: 0.3s;

  cursor: pointer;
}

.btn-text {
  padding: 16px 35px;

  text-transform: uppercase;

  color: white;

  display: inline-block;

  line-height: 1;

  font-size: 14px;

  font-weight: 700;

  letter-spacing: 0px;
}

.theme-btn:before {
  position: absolute;

  content: "";

  width: 100%;

  height: 100%;

  border: 1px solid gray;
  border-radius: 5px;

  left: 10px;

  top: 10px;

  z-index: -1;
}

/* slider */

.single-slider {
  background-size: cover;

  background-position: center center;
}

.slider-height {
  min-height: 450px;
}

.slider-content h1 {
  font-size: 60px;
  color: #719fa9 !important;
}

.slider-content h1 span {
  font-size: 60px;
}

.slider-content p {
  /* color: #fff; */

  line-height: 28px;

  margin-top: 18px;

  margin-bottom: 38px;
}

.slider-content-2 h1 {
  font-size: 72px;

  line-height: 85px;
}

.slider-active .slick-arrow:hover {
  color: #fdc800;
}

.slider-active .slick-dots {
  position: absolute;

  bottom: 40px;

  left: 0;

  right: 0;

  text-align: center;
}

.slider-active .slick-dots li {
  display: inline-block;

  margin-right: 10px;
}

.slider-active .slick-dots li:last-child {
  margin-right: 0;
}

.slider-active .slick-dots li.slick-active button {
  width: 40px;
}

.slider-active-2 .slick-dots li.slick-active button {
  border: 1px solid #fdc800;
}

.slider-content h1 {
  color: #fff;
}
@media (max-width: 767px) {
  .slider-height {
    min-height: 450px;
  }
  .slider-content h1 span {
    font-size: 35px;
  }
  .slider-content h1 {
    font-size: 30px;
    line-height: 43px;
  }
}

/**************************************
Sections (Sign-Up, Login, Features, Pricing, Sidebar)
***************************************/
.sign-up,
.login,
.features,
.pricing,
.sidebar {
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(2.5px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 15px;
}

.sign-up {
  background: rgba(255, 255, 255, 0.15);
}

.login,
.features {
  background: rgba(255, 255, 255, 0.05);
}

.pricing {
  background: rgba(255, 255, 255, 0.05) !important;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37) !important;
  backdrop-filter: blur(2.5px) !important;
  -webkit-backdrop-filter: blur(2.5px) !important;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18) !important;
}

.sidebar {
  background: #1b2431 !important;
}

/**************************************
How it works
***************************************/
.road-map-main {
  margin: 50px 0 51px;
}

.road-map-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 175px;
  @media (max-width: 991px) {
    margin-bottom: 25px;
    height: auto;
    display: block;
  }

  &::before,
  &::after {
    content: "";
    width: 100%;
    clear: both;
    display: block;
  }
}

.road-map-circle {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 25px solid transparent;
  border-top-color: #e5e7eb;
  border-right-color: #e5e7eb;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transform: rotate(45deg);

  @media (max-width: 992px) {
    position: unset;
    border: 25px solid #e5e7eb;
  }
}

.road-map-circle-text {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background-color: #719fa9;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  text-transform: capitalize;
  color: #fff;
  box-shadow: 0px 0px 10px 5px #00000021;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transform: rotate(-45deg);
}

.road-map-card {
  width: 35%;
  padding: 20px;
  z-index: 1;
  position: absolute;
  right: 0;
  border-radius: 5px;

  &::before {
    content: "";
    width: 25%;
    height: 20px;
    background: #e5e7eb;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -23%;
    z-index: -1;
  }

  @media (max-width: 991px) {
    width: 100%;
    margin-top: 30px;
    position: unset;

    &::before {
      content: "";
      width: 20px;
      height: 30%;
      top: 50%;
      transform: translateX(-50%);
      left: 50%;
    }
  }

  @media (max-width: 425px) {
    top: 45%;
  }
}

.card-head {
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  margin: 0 0 15px;
}

.card-text {
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media (max-width: 1199px) {
    -webkit-line-clamp: 4;
  }
}

.road-map-wrapper:nth-of-type(even) .road-map-circle {
  border-bottom-color: #e5e7eb;
  border-left-color: #e5e7eb;
  border-top-color: transparent;
  border-right-color: transparent;

  @media (max-width: 991px) {
    border-color: #e5e7eb;
  }
}

.road-map-wrapper:nth-of-type(even) .road-map-card {
  left: 0;

  &::before {
    right: -23%;
    left: unset;

    @media (max-width: 991px) {
      content: "";
      width: 20px;
      height: 30%;
      top: 50%;
      transform: translateX(-50%);
      left: 50%;
    }

    @media (max-width: 425px) {
      top: 45%;
    }
  }
}

/**************************************
FAQ
***************************************/
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.text-center {
  text-align: center;
}
/* 
.h2 {
  font-size: 2rem;
} */

.accordions {
  max-width: 80%;
  margin: auto;
}

.accordion .accordion__title {
  display: block;
  font-size: 1.1rem;

  font-weight: 600;
  background-color: #eff3f5;
  border-bottom: 1px solid #0002;
  padding: 20px 10px;
  color: var(--dark);
  margin-top: 10px;
  transition: all 0.25s ease;
  cursor: pointer;
}

.accordion .accordion__title input {
  appearance: none;
}

.accordion .accordion__title:after {
  transition: all 0.25s ease;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f0da";
  float: right;
  margin-right: 10px;
}

.accordion .accordion__title:has(input:checked):after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f0d7";
  float: right;
  margin-right: 10px;
}

.accordion .accordion__text {
  overflow: hidden;
  max-height: 0;
  filter: contrast(0.9);
  transition: all 0.5s ease-in-out;
  font-weight: 200;
}

.accordion .accordion__text p {
  font-weight: 100;
  line-height: 1.5rem;
  padding: 10px 10px;
  color: var(--gray-dark);
}

.accordion .accordion__title:has(input:checked) {
  color: var(--green-light);
}

.accordion .accordion__title:has(input:checked) + .accordion__text {
  max-height: 200px;
}

.mt-2 {
  margin-top: 20px;
}

.my-1 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.btn-coffee {
  position: absolute;
  right: 20px;
  bottom: 20px;
  display: inline-block;
  background-color: green;
  text-transform: uppercase;
  color: white;
  border: 1px solid #0001;
  border-radius: 10px;
  padding: 20px;
  font-weight: 600;
  box-shadow: 2px 2px 6px #0009;
  text-shadow: 1px 1px #0001;
  text-decoration: none;
  transition: all 0.25s ease;
  animation: live 0.6s infinite alternate;
  &:hover {
    background-color: olive;
  }
}

@keyframes live {
  0% {
    scale: 0.7;
  }
  100% {
    scale: 0.72;
  }
}
/**************************************
Events
***************************************/

.thumbnail-date-day {
  color: rgb(255, 255, 255);
}

.thumbnail-date-month {
  color: rgb(255, 255, 255);
}

div {
  display: block;
}

div,
span {
  line-height: 1;
}

.thumbnail {
  -webkit-touch-callout: none;
  border-radius: 3px;
  box-sizing: initial;
  height: 52px;
  min-width: 52px;
  overflow: hidden;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 52px;
}

.grey {
  background-color: #bababaa7;
}

.thumbnail .thumbnail-date span,
.thumbnail .thumbnail-date span {
  display: inline-block;
  line-height: 1;
}

.thumbnail .thumbnail-date .thumbnail-date-day {
  font-size: 24px;
  font-weight: 700;
  margin-top: 4px;
  position: relative;
  text-align: center;
  top: 4px;
  width: 100%;
}

.thumbnail .thumbnail-date .thumbnail-date-month {
  font-size: 10px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}

.tags-list-town {
  background-color: #198754;
}

.tags-list span {
  border-radius: 2px;
  color: #fff;
  cursor: default;
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  margin-bottom: 4px;
  margin-right: 5px;
  padding: 4px 6px;
  position: relative;
}

.text-over {
  font-size: 1.3em;
  font-weight: 900;
  color: #fff;
  padding: 20px;
}

.card {
  border: 0 !important;
  border-radius: 0 !important;
}

.card-img-top {
  border-radius: 0 !important;
}

.image-container {
  position: relative;
  overflow: hidden;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 57%);
  z-index: 1;
}

.image-container .card-img-top {
  position: relative;
  z-index: 0;
}

.tags-list-town {
  background-color: #9c27b0;
}
/* Top left text */
.bottom-left {
  position: absolute;
  bottom: 14px;
  left: 16px;
  z-index: 999;
}

.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
  color: #fff;
  font-size: 10px;
  z-index: 999;
}

.top-right {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 999;
}

.thumbnail {
  box-shadow: 0.5rem 0.5rem #00000085, -0.5rem -0.5rem #cccccca3;
}

.card {
  border: 1px solid #eee;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.tags-list span {
  font-size: 14px !important;
}

/**************************************
Dashboard (Home)
***************************************/
.action-box {
  background-color: #1b2431 !important;
  border: 1px solid #273142;
}

.card-list {
  @include clear();
  width: 100%;
}

.card {
  color: #ffffff;
  padding: 10px;
  border-radius: 0 !important;
  position: relative;
  border: none !important;
}

.projects {
  border: 1px solid #313d4f;
  border: 1px solid rgba(255, 255, 255, 0.125);
  margin: 5px !important;
}

.projects-header {
  color: white;
  padding: 22px;
  text-align: left;
}

.projects-header .count,
.projects-header .title {
  display: inline-block;
}

.projects-header .count {
  color: #738297;
}

.projects-header .zmdi {
  cursor: pointer;
  float: right;
  font-size: 16px;
  margin: 5px 0;
}

.projects-header .title {
  font-size: 21px;
}

.projects-header .title + .count {
  margin-left: 5px;
}

.projects-table {
  background: white;
  width: 100%;
}

.projects-table td,
.projects-table th {
  color: #738297 !important;
  padding: 10px 22px;
  vertical-align: middle;
  border-bottom: 1px solid #3a4958;
  text-align: left !important;
  font-weight: lighter;
  font-size: 13px;
  margin-left: -5px !important;
}

.projects-table td p {
  font-size: 12px;
}

.projects-table td p:last-of-type {
  color: #738297;
  font-size: 11px;
}

.projects-table th {
  background-color: #3a4958;
}

tr:hover {
  cursor: pointer;
}

.member {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0 !important;
}

.member figure {
  display: inline-block;
}

.member .member-info {
  display: inline-block;
  vertical-align: middle;
  margin-left: 7px;
}

.member img {
  border-radius: 50%;
  height: 32px;
  width: 32px;
}

.status > form {
  float: right;
}

.status-text {
  display: inline-block;
  font-size: 12px;
  margin: 11px 0;
  padding-left: 20px;
  position: relative;
}

.status-text:before {
  border: 3px solid;
  border-radius: 50%;
  content: "";
  height: 14px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 14px;
}

.status-text.status-blue:before {
  border-color: #1c93ed;
}

.status-text.status-green:before {
  border-color: #66b92e;
}

.status-text.status-orange:before {
  border-color: #da932c;
}

.status-text.status-red:before {
  border-color: #d65b4a;
}

.selectric {
  background-color: transparent;
  border-color: #313d4f;
  border-radius: 4px;
}

.selectric .label {
  color: #738297;
  line-height: 34px;
  margin-right: 10px;
  text-align: left;
}

.selectric-wrapper {
  float: right;
  width: 150px;
}

.danger-item {
  border-left: 4px solid #a84d43;
}

.zmdi {
  line-height: 1;
  vertical-align: middle;
}

.icon-large {
  font-size: 2rem;
}

/**************************************
Testimonials
***************************************/

.testimonials .card {
  width: 300px;
  transform-style: preserve-3d;
  perspective: 500px;
  border: none;
  background-color: inherit;
}

.testimonials .card .face {
  position: absolute;
  color: #fff;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.06);
  transform-style: preserve-3d;
  transition: 0.5s;
  backface-visibility: hidden;
}

.testimonials .card .face.front-face,
.testimonials .card .face.back-face {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.testimonials .card .face.front-face .name {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
}

.testimonials .card .face.front-face .stars {
  display: flex;
}

.testimonials .card .face.front-face .stars i {
  color: #ffc107;
  font-size: 18px;
}

.testimonials .card .face.front-face .review {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  margin: 10px 0;
}

.testimonials .card .face.back-face {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotateY(180deg);
}

.testimonials .card .face.back-face .info {
  text-align: center;
}

.testimonials .card .face.back-face .info h3 {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
}

.testimonials .card .face.back-face .info h4 {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}

.testimonials .card:hover .face.front-face {
  transform: rotateY(180deg);
}

.testimonials .card:hover .face.back-face {
  transform: rotateY(0deg);
}

.placement_container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.placement_item_container {
  width: 100%;
  margin: auto;
  margin-top: 70px;
  margin-bottom: 30px;
}

.placement_card {
  width: 90%;
  border: 4px solid #719fa9;
  position: relative;
  margin: auto;
  border-radius: 25px;
}

.placement_text {
  padding-top: 15px;
  text-align: center;
}

.placement_image_second {
  border-radius: 25px;
  height: 150px;
  width: 150px;
}

.placement_bottom {
  background-color: #e5e7eb;
  padding: 20px;
  text-align: center;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.placement_stories_link {
  text-decoration: none;
}

.placement_card {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.placement_bottom {
  margin-top: auto;
}

.placement_company_image {
  filter: grayscale(100%);
}

@media screen and (max-width: 600px) {
  .placement_item_container {
    width: 100%;
  }
}
/**************************************
Footer
***************************************/
footer {
  position: relative;
  background: #140b5c;
  width: 100%;
  bottom: 0;
  left: 0;
}
footer::before {
  content: "";
  position: absolute;
  left: 0;
  top: 100px;
  height: 1px;
  width: 100%;
  background: #afafb6;
}
footer .content {
  max-width: 1250px;
  margin: auto;
  padding: 30px 40px 40px 40px;
}
footer .content .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}
.content .top .logo-details {
  color: #fff;
  font-size: 30px;
}
.content .top .media-icons {
  display: flex;
}
.content .top .media-icons a {
  height: 40px;
  width: 40px;
  margin: 0 8px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  color: #fff;
  font-size: 17px;
  text-decoration: none;
  transition: all 0.4s ease;
}
.top .media-icons a:nth-child(1) {
  background: #4267b2;
}
.top .media-icons a:nth-child(1):hover {
  color: #4267b2;
  background: #fff;
}
.top .media-icons a:nth-child(2) {
  background: #1da1f2;
}
.top .media-icons a:nth-child(2):hover {
  color: #1da1f2;
  background: #fff;
}
.top .media-icons a:nth-child(3) {
  background: #e1306c;
}
.top .media-icons a:nth-child(3):hover {
  color: #e1306c;
  background: #fff;
}
.top .media-icons a:nth-child(4) {
  background: #0077b5;
}
.top .media-icons a:nth-child(4):hover {
  color: #0077b5;
  background: #fff;
}
.top .media-icons a:nth-child(5) {
  background: #ff0000;
}
.top .media-icons a:nth-child(5):hover {
  color: #ff0000;
  background: #fff;
}
footer .content .link-boxes {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
footer .content .link-boxes .box {
  width: calc(100% / 5 - 10px);
}
.content .link-boxes .box .link_name {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
  position: relative;
}
.link-boxes .box .link_name::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 2px;
  width: 35px;
  background: #fff;
}
.content .link-boxes .box li {
  margin: 6px 0;
  list-style: none;
}
.content .link-boxes .box li a {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  opacity: 0.8;
  transition: all 0.4s ease;
}
.content .link-boxes .box li a:hover {
  opacity: 1;
  text-decoration: underline;
}
.content .link-boxes .input-box {
  margin-right: 55px;
}
.link-boxes .input-box input {
  height: 40px;
  width: calc(100% + 55px);
  outline: none;
  border: 2px solid #9d9da0;
  background: #afafb6;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 15px;
  color: #5f5d5d;
  margin-top: 5px;
}
.link-boxes .input-box input::placeholder {
  color: #afafb6;
  font-size: 16px;
}
.link-boxes .input-box input[type="button"] {
  background: #fff;
  color: #140b5c;
  border: none;
  font-size: 18px;
  font-weight: 500;
  margin: 4px 0;
  opacity: 0.8;
  cursor: pointer;
  transition: all 0.4s ease;
}
.input-box input[type="button"]:hover {
  opacity: 1;
}
footer .bottom-details {
  width: 100%;
}
footer .bottom-details .bottom_text {
  max-width: 1250px;
  margin: auto;
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
}
.bottom-details .bottom_text span,
.bottom-details .bottom_text a {
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  opacity: 0.8;
  text-decoration: none;
}
.bottom-details .bottom_text a:hover {
  opacity: 1;
  text-decoration: underline;
}
.bottom-details .bottom_text a {
  margin-right: 10px;
}
@media (max-width: 900px) {
  footer .content .link-boxes {
    flex-wrap: wrap;
  }
  footer .content .link-boxes .input-box {
    width: 40%;
    margin-top: 10px;
  }
}
@media (max-width: 700px) {
  footer {
    position: relative;
  }
  .content .top .logo-details {
    font-size: 26px;
  }
  .content .top .media-icons a {
    height: 35px;
    width: 35px;
    font-size: 14px;
    line-height: 35px;
  }
  footer .content .link-boxes .box {
    width: calc(100% / 3 - 10px);
  }
  footer .content .link-boxes .input-box {
    width: 60%;
  }
  .bottom-details .bottom_text span,
  .bottom-details .bottom_text a {
    font-size: 12px;
  }
}
@media (max-width: 520px) {
  footer::before {
    top: 145px;
  }
  footer .content .top {
    flex-direction: column;
  }
  .content .top .media-icons {
    margin-top: 16px;
  }
  footer .content .link-boxes .box {
    width: calc(100% / 2 - 10px);
  }
  footer .content .link-boxes .input-box {
    width: 100%;
  }
}

.faq_header-title {
  font-size: 24px;
  color: #fff;
  margin-bottom: 30px;
}
.faq__panel {
  padding: 7px 21px;
  margin-bottom: 24px;
  border: 1px solid #777e91;
  border-radius: 7px;
}
.faq__label {
  padding-block: 7px;
  color: #f4f5f6;
  cursor: pointer;
}
.faq__panel-answer {
  color: #777e91;
  padding-top: 5px;
  padding-bottom: 7px;
}

.img-small {
  width: auto;
  height: 200px;
}
